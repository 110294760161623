const sizeMale = [
    {
        size: "XS",
        eu: "44",
        uk: "34",
        us: "34"
    },
    {
        size: "S",
        eu: "46",
        uk: "36",
        us: "36"
    }, {
        size: "M",
        eu: "48",
        uk: "38",
        us: "38"
    },
    {
        size: "L",
        eu: "50",
        uk: "40",
        us: "40"
    },
    {
        size: "XL",
        eu: "52",
        uk: "42",
        us: "42"
    },
    {
        size: "XXL",
        eu: "54",
        uk: "44",
        us: "44"
    },
    {
        size: "3XL",
        eu: "56",
        uk: "46",
        us: "46"
    },
    {
        size: "4XL",
        eu: "58",
        uk: "48",
        us: "48"
    }
]
const sizeFemale = [
    {
        size: "XXS",
        eu: "32",
        uk: "4",
        us: "2"
    },
    {
        size: "XS",
        eu: "34",
        uk: "6",
        us: "4"
    },
    {
        size: "S",
        eu: "36",
        uk: "8",
        us: "6"
    }, {
        size: "M",
        eu: "38",
        uk: "10",
        us: "8"
    },
    {
        size: "L",
        eu: "40",
        uk: "12",
        us: "10"
    },
    {
        size: "XL",
        eu: "42",
        uk: "14",
        us: "12"
    },
    {
        size: "XXL",
        eu: "44",
        uk: "16",
        us: "14"
    },
    {
        size: "3XL",
        eu: "46",
        uk: "18",
        us: "16"
    },
    {
        size: "4XL",
        eu: "48",
        uk: "20",
        us: "18"
    }
]
const shoesSize = [
    {
        eu: "34,5",
        uk: "2",
        us: "4"
    },
    {
        eu: "35",
        uk: "2,5",
        us: "5"
    },
    {
        eu: "35,5",
        uk: "3",
        us: "5,5"
    },
    {
        eu: "36",
        uk: "3,5",
        us: "6"
    },
    {
        eu: "36,5",
        uk: "3,5",
        us: "6"
    },
    {
        eu: "37",
        uk: "4",
        us: "6,5"
    },
    {
        eu: "37,5",
        uk: "4,5",
        us: "7"
    },
    {
        eu: "38",
        uk: "5",
        us: "7,5"
    },
    {
        eu: "38,5",
        uk: "5,5",
        us: "8"
    },
    {
        eu: "39",
        uk: "6",
        us: "8,5"
    },
    {
        eu: "40",
        uk: "6,5",
        us: "9"
    },
    {
        eu: "40,5",
        uk: "7",
        us: "9"
    },
    {
        eu: "41",
        uk: "7,5",
        us: "9,5"
    },
    {
        eu: "42",
        uk: "8",
        us: "9,5"
    },
    {
        eu: "42,5",
        uk: "8,5",
        us: "10"
    },
    {
        eu: "43",
        uk: "9",
        us: "10"
    },
    {
        eu: "44",
        uk: "9,5",
        us: "10,5"
    },
    {
        eu: "44,5",
        uk: "9,5",
        us: "10"
    },
    {
        eu: "45",
        uk: "10,5",
        us: "11,5"
    }, {
        eu: "46",
        uk: "11",
        us: "12"
    }, {
        eu: "47",
        uk: "12",
        us: "13"
    }, {
        eu: "48",
        uk: "12,5",
        us: "13,5"
    }, {
        eu: "49",
        uk: "13",
        us: "14"
    },

]
const gloveSize = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL"]

export {
    gloveSize,
    sizeMale,
    sizeFemale,
    shoesSize
}
