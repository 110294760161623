<template>
    <div class="background">
        <basic-card>
            <div class="login">
                <img src="@/assets/logo_blue.png" width="400px">
                <h1>{{ tm("dimasTitle") }}</h1>

                <h2>{{ tm("loginTitle") }}</h2>
                <div class="input-container">
                    <basic-input class="inputs" type="email" :placholder="tm('emailLabel')"
                        @value="setEmail"></basic-input>
                    <basic-input class="inputs" type="password" :placholder="tm('passwordLabel')"
                        @value="setPassword"></basic-input>
                </div>
                <div class="buttons-container">
                    <basic-button @click="login()">{{ tm("loginButton") }}</basic-button>
                    <basic-button>{{ tm("regesterButton") }}</basic-button>
                </div>
                <login-footer class="login-footer"></login-footer>
            </div>
        </basic-card>
    </div>
</template>
<script setup lang="ts">
import BasicButton from '@/components/basic/BasicButton.vue';
import BasicInput from '@/components/basic/BasicInput.vue'
import BasicCard from '@/components/basic/BasicCard.vue';
//import LoginCountryIcon from '@/components/login/LoginCountryIcon.vue';
import LoginFooter from '@/components/login/LoginFooter.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { BASE_URL } from '@/resources/api';
import { useLoginStore } from "@/stores/login";
import axios from 'axios';
import { useI18n } from 'vue-i18n';
const { tm } = useI18n()

const loginStore = useLoginStore();
const router = useRouter()
const email = ref()
const password = ref()

const header = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
}

const login = () => {
    router.push("/home")
    loginStore.token = "Hallo"
    const data = {
        grant_type: "",
        username: email.value,
        password: password.value,
        scope: "",
        client_id: "",
        client_secret: "",
    }
    console.log(`${BASE_URL}/v1/auth/`)
    axios.post(`${BASE_URL}/v1/auth/`, data, header).then((response) => {
        loginStore.token = response.data.access_token
        router.push("/home")
    }).catch((error) => {
        console.log(error)
        router.push("/home")
    })

}

const setEmail = (value: string) => {
    email.value = value
}
const setPassword = (value: string) => {
    password.value = value
}

</script>
<style scoped>
.login {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    width: 700px;
}

.button {
    width: 500px;
}

.inputs {
    width: 500px;
}

.login-footer {
    margin-top: 50px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.background {
    background-image: url("/src/assets/login/background_login_2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>