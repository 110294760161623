<template>
    <div class="select-container">
        <select class="custom-select" v-model="selectedValue" @change="emitChange">
            <option v-for="option, index in props.options" :key="index" :value="option.value">
                {{ option.text }}
            </option>
        </select>
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watch } from 'vue';

const props = defineProps(['value', 'options']);
const emits = defineEmits(['value']);

const selectedValue = ref(props.value);

const emitChange = () => {
    emits('value', selectedValue.value);
};

watch(() => props.value, (newValue) => {
    selectedValue.value = newValue;
});
</script>

<style scoped>
.custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: rgb(255, 255, 255);
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    width: 200px;
    cursor: pointer;
    outline: none;
    color: #000;
    transition: all 0.3s ease;
}

.custom-select::after {
    content: "▼";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.custom-select:hover,
.custom-select:focus {
    background-color: rgb(255, 255, 255);
    border-color: #bbb;
}

.select-container {
    position: relative;
    display: inline-block;
}
</style>
