<template>

    <router-link :to="props.to" class="nav-item nav-item-container" activeClass="active" exact>
        <div style="display: flex; align-items: center;">
            <font-awesome-icon :icon="props.icon" style="width: 20px;" />
            <div style="margin-left: 5px;">{{ props.text }}</div>
        </div>
    </router-link>
</template>
<script setup lang="ts">
import { defineProps } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps(["to", "activeIndex", "text", "icon", "index"]);

</script>
<style scoped>
.nav-item-container {
    border-radius: 5px;
    padding: 10px;
}

.nav-item-container:hover {
    background-color: var(--nav-item-backgound-color-shade);
}

.nav-item {
    color: var(--vav-item-text-color);
    text-decoration: none;
}

.active {
    color: var(--nav-item-text-color-highlight);
}
</style>