<template>
    <div class="admin-container">
        <search-and-pagination-table title="Center" :cols="cols"
            apiRoute="/admin/centers"></search-and-pagination-table>
    </div>
</template>

<script setup lang="ts">
import SearchAndPaginationTable from '@/components/SearchAndPaginationTable.vue'

const cols = [{ text: "Name", jsonName: "name" }, { text: "Price", jsonName: "price" }, { text: "Min. Alter", jsonName: "min_age" }, { text: "Ist Trainer", jsonName: "is_instructor" }, { text: "Kurs groupe", jsonName: "id_course_group" }, { text: "Aktive", jsonName: "active" }]

</script>
<style scoped>
.admin-container {
    padding-bottom: 50px;
}
</style>