<template>
    <div class="button">
        <slot></slot>
    </div>
</template>
<style scoped>
.button {
    background-color: var(--primary-color);
    border: none;
    color: var(--primary-color-text);
    padding: 8px 16px;
    padding-top: 10px;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.button:hover {
    background-color: var(--primary-color-shade);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
</style>