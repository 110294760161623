<template>
    <div class="container">

        <ul class="responsive-table">
            <li class="table-header">
                <div class="col" v-for="col, index in props.cols" :key="index">{{ col.text }}</div>
                <div class="col">{{ tm("actions") }}</div>
            </li>
            <div v-if="loading" class="loader-container">
                <basic-loader></basic-loader>
            </div>
            <li v-else class="table-row" v-for="row, index in props.data.rows" :key="index">
                <div class="col" :data-label="col.text" v-for="col, indexCol in props.cols" :key="indexCol">{{
                    col.prefix }}<span v-if="col.typ == 'date'">{{
                        convertDate(row[col.jsonName]) }}</span><span v-else>{{
                            row[col.jsonName] }}</span>{{ col.postfix }}
                </div>
                <div class="col" data-label="Actions">
                    <button @click="editRow(index)">{{ tm("edit") }}</button>
                    <button @click="deleteRow(index)">{{ tm("delete") }}</button>
                </div>
            </li>
        </ul>
    </div>
</template>
<script setup lang="ts">
import BasicLoader from '@/components/basic/BasicLoader.vue'
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
const { tm } = useI18n();

const props = defineProps(['cols', 'data', 'loading'])


const editRow = (index: number) => { return index }
const deleteRow = (index: number) => { return index }

const convertDate = (inputDate: string): string => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;

    return `${formattedDay}.${formattedMonth}.${year}`;
}

</script>
<style scoped>
.container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.loader-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

h2 {
    font-size: 26px;
    margin: 20px 40px;
    text-align: left;
}

.responsive-table {
    li {
        border-radius: 3px;
        padding: 25px 30px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    .table-header {
        background-color: var(--basic-table-header-background-color);
        color: var(--basic-table-header-text-color);
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        display: flex;
        justify-content: space-between;
        /* Align header columns with row content */
    }

    .table-row {
        background-color: var(--basic-table-row-background-color);
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    }

    .col {
        flex: 1;
        /* Equal width for all columns */
        white-space: pre-wrap;
    }

    @media all and (max-width: 767px) {
        .table-header {
            display: none;
        }

        li {
            display: block;
            text-align: left;
        }

        .col {
            flex-basis: 100%;
        }

        .col {
            display: flex;
            padding: 10px 0;

            &:before {
                padding-right: 10px;
                content: attr(data-label);
                flex-basis: 50%;
            }
        }
    }
}
</style>