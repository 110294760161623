<template>
    <div class="flip-card" @click="flipCard">
        <div class="flip-card-inner" :class="{ flipped }">
            <div class="flip-card-front">
                <img class="brevet" src="@/assets/DPV.jpeg" />
            </div>
            <div class="flip-card-back">
                <img class="brevet" src="@/assets/DPV2.jpeg" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

// State to track if the card is flipped or not
const flipped = ref(false);

// Function to toggle the flip state
const flipCard = () => {
    flipped.value = !flipped.value;
};
</script>

<style scoped>
.flip-card {
    background-color: transparent;
    width: 300px;
    perspective: 1000px;
    cursor: pointer;
    /* Change the cursor to indicate it's clickable */
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

/* Use a dynamic class to apply the flip transformation */
.flip-card-inner.flipped {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: #bbb;
    color: black;
}

.flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
}

.brevet {
    width: 300px;
    transform: rotate(180deg);
}
</style>
