<template>
    <div class="course-detailed-container">
        <BasicLeaveButton to="/courses" class="course-leave-button">{{ tm("leave") }}</BasicLeaveButton>
        <ThePageTitle class="course-detailed-title">Open Water Diver</ThePageTitle>
        <div class="course-modules">
            <TheDetailedCourseCard :active="true" :to="'/courses/' + course_id + '/elearning'"></TheDetailedCourseCard>
            <TheDetailedCourseCard :active="false" :to="'/courses/' + course_id + '/exam'"></TheDetailedCourseCard>
            <TheDetailedCourseCard :active="false" :to="'/courses/' + course_id + '/checklist'"></TheDetailedCourseCard>
        </div>

    </div>
</template>

<script setup lang="ts">
import BasicLeaveButton from '../basic/BasicLeaveButton.vue';
import ThePageTitle from '../ThePageTitle.vue';
import TheDetailedCourseCard from './TheDetailedCourseCard.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
const route = useRoute()
const { tm } = useI18n();

const course_id = ref(route.params.course_id);



</script>
<style scoped>
.course-detailed-container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 40px;
    margin-left: 10%;
    margin-right: 10%;
}

.course-leave-button {
    align-self: flex-start;
}

.course-detailed-title {
    align-self: center;
    margin-top: 20px;
    width: 100%;
}

.course-modules {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 40px;

}
</style>