<template>
    <div class="profile-card-container">{{ props.text }}</div>
</template>
<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps(["text"])

</script>
<style scoped>
.profile-card-container {
    background-color: var(--profile-card-background-color);
    color: var(--profile-card-color);
    border-radius: 15px;
    height: 70px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-card-container:hover {
    background-color: var(--trainer-card-background-color-light);
}
</style>