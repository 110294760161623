<template>
  <div class="home">
    <the-profile></the-profile>
    <the-page-title>{{ tm("homeTitle") }}</the-page-title>
    <div class="news-container">
      <TheNewsItem title="News One"></TheNewsItem>
      <TheNewsItem title="News Two"></TheNewsItem>
    </div>
  </div>
</template>

<script setup lang="ts">
import TheProfile from '@/components/home/TheProfile.vue';
import ThePageTitle from '@/components/ThePageTitle.vue';
import TheNewsItem from '@/components/home/TheNewsItem.vue';
import { useI18n } from 'vue-i18n';
const { tm } = useI18n();
</script>
<style scoped>
.news-container {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style>