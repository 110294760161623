<template>
    <div class="InputContainer">
        <input :type="props.type" class="input" v-model="value" :placeholder="props.placholder"
            @change="emits('value', value)">
    </div>
</template>
<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue';

const props = defineProps(['type', 'placholder']);
const emits = defineEmits(['value'])

const value = ref()

</script>
<style scoped>
.InputContainer {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    padding-left: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid #bbb;
}

.input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 0.9em;
}
</style>
