<template>
    <div class="elearing-menu-container">
        <div class="elearing-menu-list">
            <BasicLeaveButton :to="props.leaveTo">
                <slot>{{ tm("leave") }}</slot>
            </BasicLeaveButton>
            <template v-for="menuPoint, index in menuPoints" :key="index">
                <TheELearningMenuItem :to="props.to + menuPoint.path" :text="menuPoint.name">
                </TheELearningMenuItem>
            </template>
        </div>
    </div>

    <div class="elearing-menu-container-small-display">
        <div :class="{ show: !showMenu, NotShow: showMenu }" style="margin-top: 8px;">
            Module Menu
        </div>
        <div class="up-menu" @click="toggleShowMenu()" :class="{ show: !showMenu, NotShow: showMenu }">
            <FontAwesomeIcon :icon="faChevronUp" style="width: 20px;" color="#fff" />
        </div>
        <div class="down-menu" @click="toggleShowMenu()" :class="{ show: showMenu, NotShow: !showMenu }">
            <FontAwesomeIcon :icon="faChevronDown" style="width: 20px;" color="#fff" />
        </div>
        <div class="elearing-menu-list-small-display" :class="{ show: showMenu, NotShow: !showMenu }">
            <BasicLeaveButton :to="props.leaveTo" style="margin-bottom: 20px;">
                <slot>{{ tm("leave") }}</slot>
            </BasicLeaveButton>
            <template v-for="menuPoint, index in menuPoints" :key="index">
                <TheELearningMenuItem :to="props.to + menuPoint.path" :text="menuPoint.name">
                </TheELearningMenuItem>
            </template>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineProps, ref } from 'vue';
import TheELearningMenuItem from './TheELearningMenuItem.vue';
import { useRoute } from 'vue-router';
import BasicLeaveButton from '../basic/BasicLeaveButton.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useI18n } from 'vue-i18n';

const { tm } = useI18n();
const route = useRoute()
const props = defineProps(["meunIteams", "to", "leaveTo"])
const showMenu = ref(false)

const toggleShowMenu = () => {
    showMenu.value = !showMenu.value
}

const menuPoints = [
    {
        name: "Home",
        path: "home",
    },
    {
        name: "Mein Profil",
        path: "profile",
    },
]

</script>
<style scoped>
.elearing-menu-container {
    z-index: 10;
    background-color: var(--nav-side-bar-background-color);
    color: var(--nav-side-bar-text-color);
    left: 0px;
    top: 80px;
    height: 100%;
    width: 200px;
    transition: 0.5s;
    padding: 15px;
    overflow: scroll;
    position: fixed;
}

.elearing-menu-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: start;
}

.elearing-menu-container-small-display {
    background-color: var(--nav-side-bar-background-color);
    color: var(--nav-side-bar-text-color);
    display: none;
    justify-content: flex-start;
    transition: 1s;
    padding-left: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 30px;
}

.elearing-menu-list-small-display {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: start;
    width: 200px;
}

.up-menu {
    position: absolute;
    top: 100px;
    right: 25px;
    font-size: x-large;
}

.down-menu {
    position: absolute;
    top: 100px;
    right: 25px;
    font-size: x-large;
}

.show {
    display: block;
}

.NotShow {
    display: none;
}

@media (max-width: 800px) {
    .elearing-menu-container-small-display {
        display: flex;
    }

    .elearing-menu-container {
        display: none;
    }
}
</style>