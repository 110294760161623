<template>
    <div class="home">
        <div class="profile-container">
            <div class="profile-container-image-text">
                <img src="@/assets/user.png" style="width: 100px;">
                <div class="profile-container-text">
                    <div>
                        Tom Trappmmann
                    </div>
                    <div>
                        {{ tm("dimasNo") }} #25168
                    </div>
                    <div>
                        {{ tm("activeSince") }} 2024
                    </div>
                </div>
            </div>
            <div>
                <the-credit-table :data="credits"></the-credit-table>
            </div>
        </div>
        <div class="card-container">
            <the-main-card v-for="menuPoint, index in menuPoints" :key="index" :icon="menuPoint.icon" :number="22"
                :title="menuPoint.name"></the-main-card>
        </div>
    </div>
</template>

<script setup lang="ts">
import TheMainCard from '@/components/TrainerCenter/TheMainCard.vue';
import TheCreditTable from '@/components/TrainerCenter/TheCreditTable.vue';
import { faUser, faAddressCard, faCircleInfo, faPrint, faIdCardClip, faGraduationCap, faClipboardList, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useI18n } from 'vue-i18n';
const { tm } = useI18n();

const menuPoints = [
    {
        name: tm("myStudents"),
        icon: faUser,
    }, {
        name: tm("myStudents"),
        icon: faAddressCard,
    }, {
        name: tm("trainerForms"),
        icon: faCircleInfo,
    }, {
        name: tm("trainerPrint"),
        icon: faPrint,
    }, {
        name: tm("trainerCourse"),
        icon: faIdCardClip,
    }, {
        name: tm("trainerELearning"),
        icon: faGraduationCap,
    }, {
        name: tm("trainerChecklist"),
        icon: faClipboardList,
    }, {
        name: tm("trainerExam"),
        icon: faPenToSquare,
    },
]
const credits = [
    { organization: tm("my"), amount: "0Ç" },
    { organization: "IDDA Academy", amount: "100Ç" },
    { organization: "VTM-Dive", amount: "890Ç" }
]
</script>
<style lang="css" scoped>
.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.profile-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.profile-container-image-text {
    display: flex;
    align-items: center;
}

.profile-container-text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
</style>