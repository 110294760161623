<template>
    <div class="admin-container">
        <search-and-pagination-table title="Brevets" :cols="cols"
            apiRoute="/v1/admin/brevets"></search-and-pagination-table>
    </div>
</template>

<script setup lang="ts">
import SearchAndPaginationTable from '@/components/SearchAndPaginationTable.vue'

const cols = [{ text: "DiMaS", jsonName: "dimas_id", prefix: "#" }, { text: "Kurs", jsonName: "course" }, { text: "center", jsonName: "center" }, { text: "Datum", jsonName: "exam_date", typ: "date" }, { text: "Trainer", jsonName: "trainer_dimas_id", prefix: "#" }]

</script>
<style scoped>
.admin-container {
    padding-bottom: 50px;
}
</style>