<template>
    <div class="course-container">
        <the-page-title style="align-self: center;">{{ tm("courseTitle") }}</the-page-title>
        <div class="courses-container-items">
            <the-course-card></the-course-card>
        </div>
    </div>
</template>

<script setup lang="ts">
import ThePageTitle from '@/components/ThePageTitle.vue';
import TheCourseCard from '@/components/course/TheCourseCard.vue';
import { useI18n } from 'vue-i18n';
const { tm } = useI18n();
</script>
<style scoped>
.course-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.courses-container-items {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-top: 50px;
    align-self: flex-start;
    margin-left: 10%;
    margin-right: 10%;
}


@media (max-width: 800px) {
    .courses-container-items {
        align-self: center;
    }

}
</style>