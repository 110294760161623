<template>
    <div class="admin-container">
        <search-and-pagination-table title="Center" :cols="cols"
            apiRoute="/admin/centers"></search-and-pagination-table>
    </div>
</template>

<script setup lang="ts">
import SearchAndPaginationTable from '@/components/SearchAndPaginationTable.vue'

const cols = [{ text: "Name", jsonName: "name" }, { text: "E-Mail", jsonName: "email" }, { text: "Credits", jsonName: "credits" }, { text: "Address", jsonName: "address" }]

</script>
<style scoped>
.admin-container {
    padding-bottom: 50px;
}
</style>