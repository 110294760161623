<template>
    <div class="card-container-module">
        <div class="title">{{ props.title }}</div>
        <div class="number">{{ props.number }}</div>
        <font-awesome-icon class="icon" :icon="props.icon" />
    </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const props = defineProps(["title", "number", "icon"])
</script>
<style lang="css" scoped>
.card-container-module {
    background-color: var(--trainer-card-background-color);
    color: var(--trainer-card-color);
    width: 325px;
    height: 200px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.title {
    grid-area: 1 / 1 / 2 / 5;
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.number {
    grid-area: 2 / 1 / 3 / 3;
    font-size: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: var(--trainer-card-icon);
}

.icon {
    grid-area: 2 / 3 / 3 / 5;
    font-size: 80px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
}
</style>