<template>
    <template v-if="props.active">
        <router-link :to="props.to">
            <div class="deatiled-card-container">
                <BasicToolTips :text="tm('courseLockedInformations')" :active="!props.active">
                    <div class="course-container">
                        <img src="@/assets/DPV.jpeg" class="course-img" :class="{ notactive: !props.active }" />
                        <div v-if="!props.active" class="overlay">
                            <FontAwesomeIcon :icon="faLock" style="width: 20px;" color="#fff" />
                        </div>
                    </div>
                </BasicToolTips>
            </div>
        </router-link>
    </template>
    <div class="deatiled-card-container" v-else>
        <BasicToolTips :text="tm('courseLockedInformations')" :active="!props.active">
            <div class="course-container">
                <img src="@/assets/DPV.jpeg" class="course-img" :class="{ notactive: !props.active }" />
                <div v-if="!props.active" class="overlay">
                    <FontAwesomeIcon :icon="faLock" style="width: 20px;" color="#fff" />
                </div>
            </div>
        </BasicToolTips>
    </div>

</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import BasicToolTips from '../basic/BasicToolTips.vue';
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
const { tm } = useI18n();

const props = defineProps(["title", "imgage", "active", "to"])
</script>

<style scoped>
.course-container {
    position: relative;
    width: 300px;
    height: 195px;
}

.course-img {
    width: 300px;
}

.notactive {
    filter: grayscale(var(--not-active-gray-scale));
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent overlay */
}

.deatiled-card-container {
    width: 300px;
}
</style>
