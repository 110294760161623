<template>
    <div class="shop-container-category">
        <ThePageTitle>{{ tm("shopTitle") }}</ThePageTitle>
        <div class="shop-cat-container">
            <TheCategoryCard title="Credits" image="" id="1"></TheCategoryCard>
            <TheCategoryCard title="Credits" image="" id="1"></TheCategoryCard>
            <TheCategoryCard title="Credits" image="" id="1"></TheCategoryCard>
            <TheCategoryCard title="Credits" image="" id="1"></TheCategoryCard>
        </div>
    </div>
</template>

<script setup lang="ts">
import TheCategoryCard from '@/components/shop/TheCategoryCard.vue';
import ThePageTitle from '@/components/ThePageTitle.vue';
import { useI18n } from 'vue-i18n';
const { tm } = useI18n()

</script>
<style scoped>
.shop-container-category {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.shop-cat-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 40px;
    justify-content: center;
}
</style>