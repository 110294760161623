<template>
    <div v-if="show" class="modal-overlay">
        <div class="modal-content">
            <div @click="close">X</div>
            <p class="modal-text">
                <slot></slot>
            </p>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps(["show"])
const emits = defineEmits(["close"])
const close = () => {
    emits('close');
};

</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

}

.modal-content {
    background-color: #fff;
    padding: 25px;
    width: 80%;
    max-width: 1200px;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-text {
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
}

.modal-close {
    cursor: pointer;
    font-weight: bold;
    text-align: right;
    margin-bottom: 10px;
    color: #888;
}

.modal-close:hover {
    color: #555;
}

@media (max-width: 500px) {
    .modal-content {
        width: 100%;
    }
}
</style>