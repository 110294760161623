<template>
    <div class="nav-item nav-item-container" @click="toggleActive">
        {{ props.text }}

        <img src="/icons/arrow_right.svg" :class="{ rotateImage: active, rotateImageNotActive: !active }"
            class="imgage">
    </div>
    <div v-if="active" class="nested-item">
        <slot></slot>
    </div>

</template>
<script setup lang="ts">
import { ref } from 'vue';
import { defineProps } from 'vue';

const props = defineProps(["to", "text", "icon"]);

const active = ref(false)
const toggleActive = () => {
    active.value = !active.value
}

</script>
<style scoped>
.nav-item-container {
    border-radius: 5px;
    padding: 10px;
}

.nav-item-container:hover {
    background-color: var(--nav-item-backgound-color-shade);
}

.nav-item {
    color: var(--vav-item-text-color);
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.active {
    color: var(--nav-item-text-color-highlight);
}

.nested-item {
    width: 100%;
    padding-left: 10px;
    transition: 1s;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
}

.rotateImage {
    transform: rotate(90deg);
    transition: 0.4s;
}

.rotateImageNotActive {
    transform: rotate(0deg);
    transition: 0.4s;
}
</style>