import { defineStore } from "pinia";

export const useLoginStore = defineStore({
    id: "login",
    state: () => ({
        token: "",
    }),
    getters: {
    },
    actions: {
        login(token: string): void {
            this.token = token;
        },
        isLoggedIn(): boolean {
            if (this.token != "") {
                return true;
            } else {
                return false;
            }
        },
        logout(): void {
            this.token = "";
        },
    },
    persist: true,
});
