<template>
    <div class="home-container">
        <div class="home-left-container">
            <the-page-title>{{ tm("brevetTitle") }}</the-page-title>
            <div class="brevet-container">
                <the-brevet-show class="brevet-card"></the-brevet-show>
                <the-brevet-show class="brevet-card"></the-brevet-show>
                <the-brevet-show class="brevet-card"></the-brevet-show>
                <the-brevet-show class="brevet-card"></the-brevet-show>
                <the-brevet-show class="brevet-card"></the-brevet-show>
                <the-brevet-show class="brevet-card"></the-brevet-show>
                <the-brevet-show class="brevet-card"></the-brevet-show>
            </div>
        </div>
        <div class="home-right-container">
            <the-page-title>{{ tm("nextBrevet") }}</the-page-title>
            <div>
                <div class="home-right-container-image">
                    <div class="home-right-container-image-title">
                        OWD
                    </div>
                    <img class="brevet" src="@/assets/DPV.jpeg" />
                </div>
                <div class="home-right-container-image-text">
                    {{ tm("nextBrevetInformationPartOne") }} AOWD
                    {{ tm("nextBrevetInformationPartTwo") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ThePageTitle from '@/components/ThePageTitle.vue';
import TheBrevetShow from '@/components/brevets/TheBrevetShow.vue';
import { useI18n } from 'vue-i18n';
const { tm } = useI18n();
</script>
<style scoped>
.brevet-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;
}

.brevet-card {
    width: 300px;
    height: 195px;
}

.home-container {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.home-left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.brevet {
    width: 250px;
    transform: rotate(180deg);
}

.home-right-container-image {
    margin-bottom: 15px;
}

.home-right-container-image-title {
    font-size: 35px;
    margin-top: 15px;
    margin-bottom: 5px;
}

.home-right-container-image-text {
    font-size: 20px;
    max-width: 250px;
    margin-top: 15px;
    margin-bottom: 5px;
}

@media (max-width: 1100px) {
    .home-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .home-right-container {
        margin-top: 15px;
    }

}
</style>