<template>
    <div class="admin-container">
        <search-and-pagination-table title="Download" :cols="cols"
            apiRoute="/v1/admin/downloads"></search-and-pagination-table>
    </div>
</template>

<script setup lang="ts">
import SearchAndPaginationTable from '@/components/SearchAndPaginationTable.vue'

const cols = [{ text: "Name", jsonName: "name" }, { text: "Gruppe", jsonName: "group" }, { text: "Language", jsonName: "language" }, { text: "Tag", jsonName: "tag" }, { text: "Beschreibung", jsonName: "description" }, { text: "Download Anzahl", jsonName: "count" }]

</script>
<style scoped>
.admin-container {
    padding-bottom: 50px;
}
</style>