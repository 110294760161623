<template>
    <router-link class="card-container" :to="'/shop/' + route.params.category_id + '/' + props.id">
        <img src="@/assets/DPV2.jpeg" class="cat-image" />
        <h2>{{ props.title }}</h2>
    </router-link>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { defineProps } from 'vue';
const route = useRoute()

const props = defineProps(["title", "image", "id"])
</script>
<style scoped>
.card-container {
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: white;
    width: 300px;
    padding-bottom: 1px;
    text-decoration: none;
    color: black;
}

.cat-image {
    width: 300px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
</style>