<template>
    <div>
        <a>{{ tm("contact") }}</a> | <a>{{ tm("privacy") }}</a> | <a href="https://diveidda.com">{{ tm("iddaWeb") }}</a>
        | <span>{{
            tm("trainerVerification")
            }}</span> |
        <span>{{ tm("diverVerification") }}</span>
        <div style="margin-top: 10px;">DiMaS v5 (©) {{ currentYear() }} {{ tm("allRights") }}</div>
    </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
const { tm } = useI18n()


const currentYear = (): string => {
    const current = new Date()
    const date = `${current.getFullYear()}`
    return date;
}
</script>