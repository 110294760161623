<template>
    <div class="download-container">
        <ThePageTitle>{{ tm("downloadTitle") }}</ThePageTitle>
        <SearchAndPaginationTable style="width: 80%; margin-top: 10px;" :cols="cols" :apiRoute="apiRoute">
        </SearchAndPaginationTable>
    </div>
</template>

<script setup lang="ts">
import ThePageTitle from '@/components/ThePageTitle.vue';
import SearchAndPaginationTable from '@/components/SearchAndPaginationTable.vue';
import { useI18n } from 'vue-i18n';
const { tm } = useI18n();
const cols = [{ text: "Language", jsonName: "language" }, { text: "Name", jsonName: "name" }, { text: "Art", jsonName: "type" }, { text: "Download", jsonName: "download" }]
const apiRoute = "/v1/downloads"
</script>

<style scoped>
.download-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 25px;
}
</style>