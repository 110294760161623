<template>
    <TheItemDetailed v-if="hasItem"></TheItemDetailed>
    <TheItems v-else-if="hasCat">
    </TheItems>
    <TheCategorys v-else></TheCategorys>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import TheItemDetailed from '@/components/shop/TheItemDetailed.vue';
import TheCategorys from '@/components/shop/TheCategorys.vue';
import TheItems from '@/components/shop/TheItems.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { tm } = useI18n()
const route = useRoute()

const hasCat = ref(false)
const hasItem = ref(false)
const categoryId = ref(route.params.category_id);
const productId = ref(route.params.product_id);


watch(
    () => route.params.category_id,
    async (NewCategoryId) => {
        categoryId.value = NewCategoryId; // Update the ref
        hasCat.value = !!NewCategoryId;
    }
);
onMounted(() => {
    if (categoryId.value) {
        hasCat.value = true;
    }
});
watch(
    () => route.params.product_id,
    async (NewProductId) => {
        productId.value = NewProductId; // Update the ref
        hasItem.value = !!NewProductId;
        console.log(NewProductId)
        console.log(hasItem.value)
    }
);
onMounted(() => {
    console.log(productId.value)
    if (productId.value) {
        hasItem.value = true;
    }
    console.log(hasItem.value)
});


</script>
<style scoped></style>