<template>
    <div class="admin-container">
        <search-and-pagination-table title="Books" :cols="cols"
            apiRoute="/v1/admin/books"></search-and-pagination-table>
    </div>
</template>

<script setup lang="ts">
import SearchAndPaginationTable from '@/components/SearchAndPaginationTable.vue'

const cols = [{ text: "Name", jsonName: "name" }, { text: "Price", jsonName: "price" }, { text: "Aktiv", jsonName: "active" }, { text: "Languages", jsonName: "language" }]

</script>
<style scoped>
.admin-container {
    padding-bottom: 50px;
}
</style>