export const globalizationList = {
    de: {
    },
    en: {
        menu:
        {
            home: "Home",
            profile: "Mein Profil",
            brevet: "Meine Brevetierungen",
            course: "Meine Kurse",
            download: "Meine Downloads",
            map: "IDDA in der Nähe",
            standards: "Standards",
            shop: "Shop",
            trainer: "Trainerarea",
            center: "Centerarea",
            statistic: "Staistiken",
            admin: {
                admin: "Admin",
                book: "Bucher",
                center: "Center",
                diver: "Taucher",
                trainer: "Trainer",
                brevet: "Brevetierungen",
                news: "News",
                print: "Druck aufträge",
                verifikation: "Verifizieren",
                transformation: "Transformationen",
                quesquestionnaire: "Fragebögen",
                paylater: "Pay Later",
                paylaterhistory: "Pay Later History",
                download: "Download",
                downloadgroup: "Downloadgruppen",
                shop: "Shop",
                course: "Kurse",
                coursegroup: "Kursgruppen",
                exam: "Prüfungen",
                standard: "Standards",
                standardtyp: "Standard Typen",
                checklist: "Checklist"
            }
        },
        dimasTitle: "DiMaS - Divers Management System",
        loginTitle: "Login",
        emailLabel: "E-Mail",
        passwordLabel: "Passwort",
        loginButton: "Anmelden",
        regesterButton: "Kostenlos Regestrieren",
        contact: "Kontakt",
        privacy: "Datenschutz",
        iddaWeb: "IDDA Webseite",
        trainerVerification: "Trainer Verifizierung",
        diverVerification: "Taucher Verifizierung",
        allRights: "by Mr. Dehne | All rights reserved",
        edit: "Bearbeiten",
        shopTitle: "Dein IDDA Shop",
        delete: "Löschen",
        actions: "Aktionen",
        readMore: "Mehr lesen",
        dimasNo: "DiMaS Nr.",
        credits: "Credits",
        orga: "Organization",
        search: "Suchen ... ",
        brevetTitle: "Brevetierungen",
        courseTitle: "Meine Course",
        downloadTitle: "Download",
        homeTitle: "IDDA News",
        changePic: "Bild Ändern",
        infoMedDoc: "Diese Informationen kannst du mit deinem IDDA College teilen für deine Ausbildung",
        email: "E-Mail",
        phone: "Telefon",
        bday: "Gebursdatum",
        street: "Straße",
        houseNumber: "Nr.",
        postalCode: "Postleitzahl",
        city: "Stadt",
        country: "Land",
        newsletter: "Newsletter",
        coordinates: "Koordinaten",
        latitude: "Breitegrad",
        longitude: "Längegrad",
        medicalQuestion: "Medizinischer Fragebogen",
        disclaimer: "Haftungsausschluss",
        clothingSizes: 'Konfektionsgrößen',
        activeSince: "Aktive seit",
        trainerMyStudents: "Meine Schüler",
        trainerBrevet: "Brevetierungen",
        trainerForms: "Formulare",
        trainerPrint: "Druckaufträge",
        trainerCourse: "Kurse",
        trainerELearning: "E-Learning",
        trainerChecklist: "Checkliste",
        trainerExam: "Exam",
        courseLockedInformations: "Dieses Modul ist nicht freigeschaltet. Bitte frage deinen IDDA Trainer dieses Modul freizuschalten.",
        leave: "Verlassen",
        my: "Meine",
        saveButton: "Speichern",
        errors: {
            login: "Überprüfen sie ihre eingabe E-Mail oder Password ist Falsch.",
        },
        yes: "Ja",
        no: "Nein",
        nextBrevet: "Starte dein IDDA",
        nextBrevetInformationPartOne: "Lerne mehr über den ",
        nextBrevetInformationPartTwo: "Informiere dich bei deinem IDDA College!",
        medical: {
            title: "Taucher Medizincheck | Kandidaten-Fragebogen",
            description: "Sporttauchen und Freitauchen erfordert eine gute körperliche und geistige Gesundheit. Es gibt einige wenige medizinische Bedingungen, die beim Tauchen ein Risiko darstellen können und unten aufgeführt sind. Personen, die an einer dieser Erkrankungen leiden oder dafür prädisponiert sind, sollten von einem Arzt untersucht werden. Dieser medizinische Teilnehmer-Fragebogen für Taucher bietet die Grundlage für die Entscheidung, ob Sie sich um diese Untersuchung bemühen sollten. Wenn Sie Bedenken bezüglich Ihrer Tauchtauglichkeit haben, die sich nicht aus diesem Formular erschließen, sollten Sie sich vor dem Tauchen mit Ihrem Arzt beraten. Wenn Sie sich krank fühlen, vermeiden Sie das Tauchen. Wenn Sie glauben, dass Sie möglicherweise an einer ansteckenden Krankheit leiden, schützen Sie sich und andere, indem Sie nicht an der Tauchausbildung und/oder an Tauchaktivitäten teilnehmen. Bezugnahmen auf “Tauchen” auf diesem Formular umfassen sowohl Sporttauchen als auch Freitauchen. Es ist in erster Linie als Screening im Sinne einer medizinischen Voruntersuchung für Tauchanfänger gedacht, eignet sich aber auch für Taucher, die eine fortgeschrittene Ausbildung absolvieren. Beantworten Sie zu Ihrer Sicherheit und der Sicherheit anderer, die mit Ihnen tauchen, alle Fragen ehrlich.",
            instructionsTitle: "Anleitung",
            instructions: "Füllen Sie diesen Fragebogen als Voraussetzung für einen Sporttauch- oder Freitauch-Kurs aus.\n Hinweis für Frauen: Wenn Sie schwanger sind oder versuchen, schwanger zu werden, tauchen Sie nicht.",
            additionasQuestion: "Zusätzuliche fragen",
            1: "Ich hatte Probleme mit Lunge/Atmung, Herz, Blut oder wurde mit COVID-19 diagnostiziert.",
            2: "Ich bin über 45 Jahre alt.",
            3: "Ich habe Mühe, mich mäßig zu bewegen (z.B. 1,6 Kilometer/eine Meile in 14 Minuten laufen oder 200 Meter/Yard schwimmen, ohne mich auszuruhen) ODER ich war in den letzten 12 Monaten aus Fitness- oder Gesundheitsgründen nicht in der Lage, an einer normalen körperlichen Aktivität teilzunehmen.",
            4: "Ich habe Probleme mit meinen Augen, Ohren oder Nasengänge/Nasennebenhöhlen gehabt.",
            5: "Ich bin in den letzten 12 Monaten operiert worden ODER ich habe anhaltende Probleme im Zusammenhang mit früheren Operationen.",
            6: "Ich habe das Bewusstsein verloren, hatte Migräne, Krampfanfälle, einen Schlaganfall, eine schwere Kopfverletzung oder leide an einer anhaltenden neurologischen Verletzung oder Erkrankung. ",
            7: "Ich befinde mich derzeit wegen psychologischer Probleme, Persönlichkeitsstörungen, Panikattacken, Drogen- oder Alkoholabhängigkeit in Behandlung (oder habe in den letzten fünf Jahren eine Behandlung benötigt) oder es wurde bei mir eine Lernbehinderung diagnostiziert. ",
            8: "Ich habe Rückenprobleme, Leistenbruch, Geschwüre (Ulcus) oder Diabetes gehabt.",
            9: "Ich habe Magen- oder Darmprobleme gehabt, darunter auch kürzlich Durchfall.",
            10: "Ich nehme verschreibungspflichtige Medikamente ein (mit Ausnahme von Antibabypillen oder Malariamitteln außer Mefloquin (Lariam).",
            A: {
                A: "KASTEN A – ICH HABE/HATTE:",
                1: "Thoraxchirurgie, Herzchirurgie, Herzklappenoperation, Stentimplantation oder einen Pneumothorax (kollabierte Lunge).",
                2: "Asthma, Keuchen, schwere Allergien, Heuschnupfen oder verstopfte Atemwege innerhalb der letzten 12 Monate, die meine körperliche Aktivität/Training einschränken.",
                3: "Ein Problem oder eine Krankheit, die mein Herz betrifft, wie z.B.: Angina pectoris, Brustschmerzen bei Anstrengung, Herzinsuffizienz, Immersions-Lungenödem, Herzinfarkt oder Schlaganfall, ODER ich nehme Medikamente gegen eine Herzerkrankung ein.",
                4: "Wiederholt Bronchitis und derzeit Husten innerhalb der letzten 12 Monate ODER es wurde ein Emphysem diagnostiziert.",
                5: "Eine COVID-19 Diagnose",
            },
            B: {
                B: "KASTEN B – ICH BIN ÜBER 45 JAHRE ALT UND:",
                1: "Derzeit rauche oder inhaliere ich Nikotin mit anderen Mitteln.",
                2: "Ich habe einen hohen Cholesterinspiegel.",
                3: "Ich habe hohen Blutdruck.",
                4: "Ich hatte einen plötzlichen Tod eines nahen Blutsverwandten oder eine Herzkrankheit oder einen Schlaganfall vor meinem 50. Lebensjahr ODER ich habe eine familiäre Vorgeschichte mit Herzkrankheiten vor dem 50. Lebensjahr (einschließlich abnormaler Herzrhythmen, koronarer Herzkrankheit oder Erkrankung des Herzmuskels).",
            },
            C: {
                C: "KASTEN C – ICH HABE/HATTE:",
                1: "Innerhalb der letzten 6 Monate eine Operation der Nasennebenhöhlen.",
                2: "Ohrkrankheit oder Ohrchirurgie, Hörverlust oder Gleichgewichtsstörungen.",
                3: "Wiederkehrend Entzündungen der Nasennebenhöhlen innerhalb der letzten 12 Monate.",
                4: "Augenoperation innerhalb der letzten 3 Monate.",
            },
            D: {
                D: "Kopfverletzung mit Bewusstseinsverlust innerhalb der letzten 5 Jahre.",
                1: "Anhaltende neurologische Verletzung oder Erkrankung.",
                2: "Wiederkehrende Migränekopfschmerzen innerhalb der letzten 12 Monate oder Einnahme von Medikamenten zu deren Verhinderung.",
                3: "Blackouts oder Ohnmachtsanfälle (vollständiger/teilweiser Bewusstseinsverlust) innerhalb der letzten 5 Jahre.",
                4: "Epilepsie, epileptische Anfälle oder Krampfanfälle ODER nehme Medikamente ein, um sie zu verhindern.",
            },
            E: {
                E: "KASTEN E – ICH HABE/HATTE:",
                1: "Psychische, psychologische Probleme oder im Gesundheitsverhalten, die eine medizinische/psychiatrische Behandlung erfordern.",
                2: "Schwere Depressionen, Selbstmordgedanken, Panikattacken, unkontrollierte bipolare Störung, die eine medikamentöse/psychiatrische Behandlung erfordern.",
                3: "Es wurde ein psychischer Gesundheitszustand oder eine Lern-/Entwicklungsstörung diagnostiziert, die eine kontinuierliche Betreuung erfordert.",
                4: "Eine Drogen- oder Alkoholabhängigkeit, die innerhalb der letzten 5 Jahre behandelt werden musste.",
            },
            F: {
                F: "KASTEN F – ICH HABE/HATTE:",
                1: "Wiederkehrende Rückenprobleme in den letzten 6 Monaten, die meine tägliche Routineaktivität einschränken.",
                2: "Rücken- oder Wirbelsäulenoperationen innerhalb der letzten 12 Monate.",
                3: "Diabetes, entweder medikamentös oder Diät-eingestellt ODER Schwangerschaftsdiabetes innerhalb der letzten 12 Monate.",
                4: "Einen nicht operierten Leistenbruch, der meine körperlichen Fähigkeiten einschränkt.",
                5: "Akute oder unbehandelte Geschwüre (Ulkus), Problemwunden oder Geschwüroperationen innerhalb der letzten 6 Monate.",
            },
            G: {
                G: "KASTEN G – ICH HATTE:",
                1: "Stoma-Operation und keine ärztliche Erlaubnis zum Schwimmen oder zur körperlichen Betätigung.",
                2: "Dehydrierung, die innerhalb der letzten 7 Tage eine medizinische Maßnahme erforderte.",
                3: "Aktive oder unbehandelte Magen- oder Darmgeschwüre oder diesbezügliche Operationen innerhalb der letzten 6 Monate.",
                4: "Häufiges Sodbrennen, Aufstoßen oder gastroösophageale Refluxkrankheit (GERD).",
                5: "Aktive oder unkontrollierte Colitis ulcerosa oder Morbus Crohn.",
                6: "Adipositas Operationen innerhalb der letzten 12 Monate.",
            },
            candidateDeclarationTitle: "Kandidaten Erklärung",
            candidateDeclarationQuestion: "Wenn Sie alle Fragen mit NEIN beantwortet haben, ist eine medizinische Beurteilung nicht erforderlich. Bitte lesen Sie die nachstehende Teilnehmererklärung und stimmen Sie ihr zu, indem sie auf Zustimmen drücken.",
            candidateDeclaration: "Erklärung des Teilnehmers: Ich habe alle Fragen ehrlich beantwortet und verstehe, dass ich die Verantwortung für alle Konsequenzen übernehme, die sich aus Fragen ergeben, die ich möglicherweise ungenau beantwortet habe, oder für mein Versäumnis, bestehende oder frühere Gesundheitszustände offenzulegen.",
            candidateDeclarationExeption: "Da Frage Nummer 3, 5 und 10 bzw. eine Zusätzliche Frage mit JA beantwortet haben, ist eine Ärztliche Beurteilung nötig. Finde weitere infomrationen unter <a href='https://www.uhms.org/resources/featured-resources/recreational-diving-medical-screening-system?format=html' target='_blank'>www.uhms.org</a>.",
            accept: "Zustimmen",
        }
    }
}
