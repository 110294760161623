<template>
    <div class="shop-container-items">
        <BasicLeaveButton to="/shop">Leave</BasicLeaveButton>
        <ThePageTitle>Category</ThePageTitle>
        <div class="shop-item-container">
            <TheItemCard title="Credits" image="" id="1"></TheItemCard>
            <TheItemCard title="Credits" image="" id="1"></TheItemCard>
            <TheItemCard title="Credits" image="" id="1"></TheItemCard>
            <TheItemCard title="Credits" image="" id="1"></TheItemCard>
            <TheItemCard title="Credits" image="" id="1"></TheItemCard>
            <TheItemCard title="Credits" image="" id="1"></TheItemCard>
            <TheItemCard title="Credits" image="" id="1"></TheItemCard>
            <TheItemCard title="Credits" image="" id="1"></TheItemCard>
            <TheItemCard title="Credits" image="" id="1"></TheItemCard>
            <TheItemCard title="Credits" image="" id="1"></TheItemCard>
            <TheItemCard title="Credits" image="" id="1"></TheItemCard>
            <TheItemCard title="Credits" image="" id="1"></TheItemCard>


        </div>
    </div>
</template>

<script setup lang="ts">
import ThePageTitle from '@/components/ThePageTitle.vue';
import TheItemCard from './TheItemCard.vue';
import { useI18n } from 'vue-i18n';
import BasicLeaveButton from '../basic/BasicLeaveButton.vue';
const { tm } = useI18n()

</script>
<style scoped>
.shop-container-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.shop-item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 40px;
    justify-content: center;
}
</style>