<template>
    <div class="admin-container">
        <search-and-pagination-table title="Download Gruppen" :cols="cols"
            apiRoute="/v1/admin/downloadgroups"></search-and-pagination-table>
    </div>
</template>

<script setup lang="ts">
import SearchAndPaginationTable from '@/components/SearchAndPaginationTable.vue'

const cols = [{ text: "ID", jsonName: "id", prefix: "#" }, { text: "Name", jsonName: "name" }, { text: "Beschreibung", jsonName: "description" }]

</script>
<style scoped>
.admin-container {
    padding-bottom: 50px;
}
</style>