import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createI18n } from 'vue-i18n'
import { globalizationList } from '@/resources/language/index';

const i18n = createI18n({
    legacy: false,
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages: globalizationList, // set locale messages
    preserveDirectiveContent: true
})

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


/* CSS variables */
import './resources/variables.css';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

createApp(App).use(router).use(i18n).use(pinia).component('font-awesome-icon', FontAwesomeIcon).mount("#app");
