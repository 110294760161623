<template>
    <div><img src="@/assets/DPV.jpeg" class="course-img">
        <div>Sccouter</div>

    </div>
</template>

<script setup lang="ts">
</script>
<style scoped>
.course-img {
    transform: rotate(180deg);
    width: 300px;
}
</style>