<template>
    <div>
        <TheELearningMenu :to="'/courses/' + course_id + '/elearning/'" :leaveTo="'/courses/' + course_id">
        </TheELearningMenu>
    </div>
</template>
<script lang="ts" setup>
import TheELearningMenu from '@/components/e-learning/TheELearningMenu.vue';
import { useRoute } from 'vue-router';
const route = useRoute()
const course_id = route.params.course_id

</script>
<style scoped></style>