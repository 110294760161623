<template>
    <div class="the-profile-container">
        <img src="@/assets/user.png" style="width: 100px;">
        <div style="text-align: left;" class="text-container">
            <div><strong>Lukas Dehne</strong></div>
            <div>{{ tm("dimasNo") }} #12345</div>
            <div>Speciality Diver</div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
const { tm } = useI18n();


</script>
<style lang="css" scoped>
.the-profile-container {
    display: flex;
    margin: 15px;
}

.text-container {
    margin-left: 15px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>