<template>
    <the-course-detailed-page v-if="is_detailed"></the-course-detailed-page>
    <the-course-main-page v-else></the-course-main-page>
</template>

<script setup lang="ts">
import TheCourseMainPage from '@/components/course/TheCourseMainPage.vue';
import TheCourseDetailedPage from '@/components/course/TheCourseDetailedPage.vue';
import { ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute()

const is_detailed = ref(false)
const course_id = ref(route.params.course_id);

watch(
    () => route.params.course_id,
    async (new_course_id) => {
        course_id.value = new_course_id; // Update the ref
        is_detailed.value = !!new_course_id; // 
    }
);
onMounted(() => {
    if (course_id.value) {
        is_detailed.value = true;
    }
});

</script>
<style scoped>
.course-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.courses-container-items {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-top: 50px;
    align-self: flex-start;
    margin-left: 10%;
    margin-right: 10%;
}


@media (max-width: 800px) {
    .courses-container-items {
        align-self: center;
    }

}
</style>