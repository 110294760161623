<template>
    <div class="profile-container">
        <div class="profile-top-container">
            <div class="profile-top-user-container">
                <div>
                    <img src="@/assets/user.png" style="width: 100px;">
                    <div><font-awesome-icon :icon="faRepeat" style="width: 20px;" />{{ tm("changePic") }}</div>
                </div>
                <div class="profile-top-text-container">
                    <div>
                        Tom Trappmmann
                    </div>
                    <div>
                        {{ tm("dimasNo") }} #25168
                    </div>
                </div>
            </div>
            <div class="profile-language-container">
                <basic-select :options="languages" value="es"></basic-select>
            </div>
        </div>
        <div class="bottom-container">
            <div class="container-questionares">
                <div style="width: 250px;margin-bottom: 10px;">{{ tm("infoMedDoc") }}</div>
                <the-profile-card :text="tm('medicalQuestion')" @click="showMedical = true"></the-profile-card>
                <the-profile-card :text="tm('disclaimer')"></the-profile-card>
                <the-profile-card :text="tm('clothingSizes')" @click="showClothing = true"></the-profile-card>
            </div>
            <div class="bottom-container-inputs">
                <div>
                    <div>{{ tm("email") }}</div>
                    <BasicInput type="email" placholder="E-Mail"></BasicInput>
                </div>
                <div>
                    <div>{{ tm("phone") }}</div>
                    <BasicInput type="phone" placholder="Telefon"></BasicInput>
                </div>
                <div>
                    <div>{{ tm("bday") }}</div>
                    <BasicInput type="date" placholder="Gebursdatum"></BasicInput>
                </div>
                <div>
                    <div>{{ tm("street") }} / {{ tm("houseNumber") }}</div>
                    <BasicInput type="text" placholder="Straße / Nr"></BasicInput>
                </div>
                <div>
                    <div>{{ tm("postalCode") }}</div>
                    <BasicInput type="text" placholder="Postleitzahl"></BasicInput>
                </div>
                <div>
                    <div>{{ tm("city") }}</div>
                    <BasicInput type="text" placholder="Stadt"></BasicInput>
                </div>
                <div class="country-container">
                    <div>{{ tm("country") }}</div>
                    <BasicSelect></BasicSelect>
                </div>
                <div>
                    <div>{{ tm("newsletter") }}</div>
                    <BasicToggle></BasicToggle>
                </div>
                <div>
                    <div>{{ tm("coordinates") }}</div>
                    <div class="coordinates-container">
                        <div class="coordinates-container-div">
                            <div>{{ tm("latitude") }}</div>
                            <BasicInput type="number" :placholder="tm('latitude')"></BasicInput>
                        </div>
                        <div class="coordinates-container-div">
                            <div>{{ tm("longitude") }}</div>
                            <BasicInput type="number" :placholder="tm('longitude')"></BasicInput>
                        </div>
                    </div>
                </div>
                <div>
                    <BasicButton>{{ tm("saveButton") }}</BasicButton>
                </div>
                <BasicModule :show="showMedical" @close="handleMedical">
                    <TheMedicalQuestion></TheMedicalQuestion>
                </BasicModule>
                <BasicModule :show="showClothing" @close="handleClothing">
                    <TheClothingSize></TheClothingSize>
                </BasicModule>

            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import TheProfileCard from '@/components/profile/TheProfileCard.vue';
import BasicInput from '@/components/basic/BasicInput.vue';
import BasicSelect from '@/components/basic/BasicSelect.vue';
import BasicToggle from '@/components/basic/BasicToggle.vue';
import BasicButton from '@/components/basic/BasicButton.vue';
import BasicModule from '@/components/basic/BasicModule.vue';
import { useI18n } from 'vue-i18n';
import TheMedicalQuestion from '@/components/profile/TheMedicalQuestion.vue';
import TheClothingSize from '@/components/profile/TheClothingSize.vue';
const { tm } = useI18n();

const showMedical = ref(false)
const showClothing = ref(false)
const handleMedical = () => {
    showMedical.value = false;
};
const handleClothing = () => {
    showClothing.value = false;
};
const languages = [
    {
        "value": "es",
        "text": "Spansih",
    },
    {
        "value": "en",
        "text": "Englisch",
    },
    {
        "value": "de",
        "text": "Deutsch",
    }

]


</script>
<style lang="css" scoped>
.profile-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.profile-top-container {
    width: 100%;
    display: flex;
    padding-left: 7.5%;
    padding-right: 7.5%;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;
}

.profile-top-user-container {
    display: flex;
    flex-direction: row;
}

.profile-top-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.profile-language-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 7.5%;
    padding-right: 7.5%;
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 25px;
}

.container-questionares {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    margin-right: 25px;
}

.bottom-container-inputs {
    display: flex;
    width: 70%;
    flex-direction: column;
    text-align: start;
    gap: 15px;
}

.coordinates-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
}

.coordinates-container-div {
    width: 49%;
}

.country-container {
    width: 100%;
}

@media (max-width: 1100px) {
    .bottom-container {
        justify-content: center;
    }

    .container-questionares {
        margin-right: 0px;
        margin-bottom: 25px;
    }

    .coordinates-container-div {
        width: 100%;
    }
}
</style>