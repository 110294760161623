<template>
    <router-link :to="props.to" class="elearning-menu-item elearning-menu-item-container" activeClass="active" exact>
        <div style="display: flex; align-items: center;">
            <div style="margin-left: 5px;">{{ props.text }}</div>
        </div>
    </router-link>
</template>
<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps(["to", "activeIndex", "text", "index"]);

</script>
<style scoped>
.elearning-menu-item-container {
    border-radius: 5px;
    padding: 10px;
}

.elearning-menu-item-container:hover {
    background-color: var(--nav-item-backgound-color-shade);
}

.elearning-menu-item {
    color: var(--vav-item-text-color);
    text-decoration: none;
}

.active {
    color: var(--nav-item-text-color-highlight);
}
</style>