<template>
    <h2>
        {{ tm("medical.title") }}
    </h2>
    <div>
        {{ tm("medical.description") }}
    </div>
    <h3>
        {{ tm("medical.instructionsTitle") }}
    </h3>
    <div>
        {{ tm("medical.instructions") }}
    </div>
    <form>
        <ul class="responsive-table">
            <li class="table-row" v-for="index in 10" :key="index">
                <div class="col" style="width: 30px;" data-label="Nummer">
                    {{ index }}
                </div>
                <div class="col" data-label="Frage" style="width: 100%;">
                    {{ tm("medical." + index) }}
                </div>
                <div class="col" style="width: 75px;" data-label="Antwort">
                    <BasicRadioButton :name="'index' + index" v-model="answers[index - 1]" :value="true">{{ tm("yes") }}
                    </BasicRadioButton>
                    <BasicRadioButton :name="'index' + index" v-model="answers[index - 1]" :value="false">{{ tm("no") }}
                    </BasicRadioButton>
                </div>
            </li>
        </ul>
        <div class="container-additional-questions">
            <h3 v-if="ShowA || ShowB || ShowC || ShowD || ShowE || ShowF || ShowG" class="container-tittle">{{
                tm("medical.additionasQuestion")
            }}</h3>
            <ul class="responsive-table" v-if="ShowA">
                <li class="table-row" v-for="index in 5" :key="index">
                    <div class="col" data-label="Frage" style="width: 100%;">
                        {{ tm("medical.A." + index) }}
                    </div>
                    <div class="col" style="width: 75px;" data-label="Antwort">
                        <BasicRadioButton :name="'index' + index" v-model="answersA[index - 1]" :value="true">{{
                            tm("yes")
                        }}
                        </BasicRadioButton>
                        <BasicRadioButton :name="'index' + index" v-model="answersA[index - 1]" :value="false">{{
                            tm("no")
                        }}
                        </BasicRadioButton>
                    </div>
                </li>
            </ul>
            <ul class="responsive-table" v-if="ShowB">
                <li class="table-row" v-for="index in 4" :key="index">
                    <div class="col" data-label="Frage" style="width: 100%;">
                        {{ tm("medical.B." + index) }}
                    </div>
                    <div class="col" style="width: 75px;" data-label="Antwort">
                        <BasicRadioButton :name="'index' + index" v-model="answersB[index - 1]" :value="true">{{
                            tm("yes")
                        }}
                        </BasicRadioButton>
                        <BasicRadioButton :name="'index' + index" v-model="answersB[index - 1]" :value="false">{{
                            tm("no")
                        }}
                        </BasicRadioButton>
                    </div>
                </li>
            </ul>
            <ul class="responsive-table" v-if="ShowC">
                <li class="table-row" v-for="index in 4" :key="index">
                    <div class="col" data-label="Frage" style="width: 100%;">
                        {{ tm("medical.C." + index) }}
                    </div>
                    <div class="col" style="width: 75px;" data-label="Antwort">
                        <BasicRadioButton :name="'index' + index" v-model="answersC[index - 1]" :value="true">{{
                            tm("yes")
                        }}
                        </BasicRadioButton>
                        <BasicRadioButton :name="'index' + index" v-model="answersC[index - 1]" :value="false">{{
                            tm("no")
                        }}
                        </BasicRadioButton>
                    </div>
                </li>
            </ul>
            <ul class="responsive-table" v-if="ShowD">
                <li class="table-row" v-for="index in 4" :key="index">
                    <div class="col" style="width: 30px;" data-label="Nummer">
                        {{ "D " + index }}
                    </div>
                    <div class="col" data-label="Frage" style="width: 100%;">
                        {{ tm("medical.D." + index) }}
                    </div>
                    <div class="col" style="width: 75px;" data-label="Antwort">
                        <BasicRadioButton :name="'index' + index" v-model="answersD[index - 1]" :value="true">{{
                            tm("yes")
                        }}
                        </BasicRadioButton>
                        <BasicRadioButton :name="'index' + index" v-model="answersD[index - 1]" :value="false">{{
                            tm("no")
                        }}
                        </BasicRadioButton>
                    </div>
                </li>
            </ul>
            <ul class="responsive-table" v-if="ShowE">
                <li class="table-row" v-for="index in 4" :key="index">
                    <div class="col" style="width: 30px;" data-label="Nummer">
                        {{ "E " + index }}
                    </div>
                    <div class="col" data-label="Frage" style="width: 100%;">
                        {{ tm("medical.E." + index) }}
                    </div>
                    <div class="col" style="width: 75px;" data-label="Antwort">
                        <BasicRadioButton :name="'index' + index" v-model="answersE[index - 1]" :value="true">{{
                            tm("yes")
                        }}
                        </BasicRadioButton>
                        <BasicRadioButton :name="'index' + index" v-model="answersE[index - 1]" :value="false">{{
                            tm("no")
                        }}
                        </BasicRadioButton>
                    </div>
                </li>
            </ul>
            <ul class="responsive-table" v-if="ShowF">
                <li class="table-row" v-for="index in 5" :key="index">
                    <div class="col" data-label="Frage" style="width: 100%;">
                        {{ tm("medical.F." + index) }}
                    </div>
                    <div class="col" style="width: 75px;" data-label="Antwort">
                        <BasicRadioButton :name="'index' + index" v-model="answersF[index - 1]" :value="true">{{
                            tm("yes")
                        }}
                        </BasicRadioButton>
                        <BasicRadioButton :name="'index' + index" v-model="answersF[index - 1]" :value="false">{{
                            tm("no")
                        }}
                        </BasicRadioButton>
                    </div>
                </li>
            </ul>
            <ul class="responsive-table" v-if="ShowG">
                <li class="table-row" v-for="index in 6" :key="index">
                    <div class="col" data-label="Frage" style="width: 100%;">
                        {{ tm("medical.G." + index) }}
                    </div>
                    <div class="col" style="width: 75px;" data-label="Antwort">
                        <BasicRadioButton :name="'index' + index" v-model="answersG[index - 1]" :value="true">{{
                            tm("yes")
                        }}
                        </BasicRadioButton>
                        <BasicRadioButton :name="'index' + index" v-model="answersG[index - 1]" :value="false">{{
                            tm("no")
                        }}
                        </BasicRadioButton>
                    </div>
                </li>
            </ul>
        </div>
        <div>

            <h3>{{ tm("medical.candidateDeclarationTitle") }}</h3>
            <div v-html="tm('medical.candidateDeclarationExeption')" v-if="needDoc"></div>
            <div v-else>
                <div>{{ tm("medical.candidateDeclarationQuestion") }}</div>
                <br>
                <div>{{ tm("medical.candidateDeclaration") }}</div>
                <BasicButton style="margin-top: 10px;" @click="showModal = true">{{ tm("medical.accept") }}
                </BasicButton>
                <BasicConfirm :show="showModal" @close="handleConfirmation">Ich möchte die angaben speichern
                </BasicConfirm>
            </div>
        </div>
    </form>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import BasicRadioButton from '../basic/BasicRadioButton.vue';
import { useI18n } from 'vue-i18n';
import BasicButton from '../basic/BasicButton.vue';
import BasicConfirm from '../basic/BasicConfirm.vue';

const { tm } = useI18n();
const answers = ref(Array(10).fill(null));
const answersA = ref(Array(5).fill(null));
const answersB = ref(Array(4).fill(null));
const answersC = ref(Array(4).fill(null));
const answersD = ref(Array(4).fill(null));
const answersE = ref(Array(4).fill(null));
const answersF = ref(Array(5).fill(null));
const answersG = ref(Array(6).fill(null));
const ShowA = ref(null);
const ShowB = ref(null);
const ShowC = ref(null);
const ShowD = ref(null);
const ShowE = ref(null);
const ShowF = ref(null);
const ShowG = ref(null);


const needDocQuestion3510 = ref(false)
const needDocQuestionAdditional = ref(false)

const needDoc = computed(() => {
    return needDocQuestion3510.value || needDocQuestionAdditional.value
})


const showModal = ref(false);

const handleConfirmation = (confirmed) => {
    if (confirmed) {
        alert("Button pressed!");
    }
    showModal.value = false;
};


watch(answers, (_) => {
    ShowA.value = answers.value[0]
    ShowB.value = answers.value[1]
    ShowC.value = answers.value[3]
    ShowD.value = answers.value[5]
    ShowE.value = answers.value[6]
    ShowF.value = answers.value[7]
    ShowG.value = answers.value[8]
    if (answers.value[2] || answers.value[4] || answers.value[9]) {
        needDocQuestion3510.value = true
    } else {
        needDocQuestion3510.value = false
    }
}, { deep: true });

</script>

<style scoped>
.responsive-table {
    padding-left: 0px;

    li {
        border-radius: 3px;
        padding: 25px 30px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    .table-row {
        background-color: var(--basic-table-row-background-color);
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    }

    .col {
        /* Equal width for all columns */
        white-space: pre-wrap;
    }

    @media all and (max-width: 500px) {

        li {
            display: block;
            text-align: left;
        }

        .col {
            display: flex;
            padding: 10px 0;

            &:before {
                padding-right: 10px;
                content: attr(data-label);
                flex-basis: 50%;
            }
        }
    }
}

.container-tittle {
    padding: 25px 30px;
}
</style>