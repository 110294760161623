<template>
    <div class="tooltip-wrapper" ref="wrapper" @mouseenter="showTooltip" @mouseleave="hideTooltip">
        <div v-if="isVisible && props.active" class="tooltip">
            {{ text }}
        </div>
        <slot></slot>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue';

const props = defineProps(["text", "active"])

const isVisible = ref(false);
const wrapper = ref<HTMLElement | null>(null);

const showTooltip = () => {
    isVisible.value = true;
};

const hideTooltip = () => {
    isVisible.value = false;
};



</script>

<style scoped>
.tooltip-wrapper {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    top: 25%;
    left: 21.5px;
    z-index: 5;
    background-color: var(--tool-tip-background-color);
    color: var(--tool-tip-color);
    padding: 5px;
    border-radius: 4px;
    font-size: 0.875rem;
    width: 250px;

}
</style>