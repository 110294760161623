<template>
    <div class="basic-card-container">
        <slot></slot>
    </div>
</template>
<style scoped>
.basic-card-container {
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: white;
    padding: 15px;
}
</style>