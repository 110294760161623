<template>
    <div>
        <template v-if="is_detailed">
            <div class="standard-detail-container">
                <TheELearningMenu :to="'/standards/' + route.params.standard_id" leaveTo="/standards">
                </TheELearningMenu>
                <TheELearningPage></TheELearningPage>
            </div>
        </template>
        <template v-else>
            <div class="standard-container">
                <ThePageTitle title="Standards"></ThePageTitle>
                <SearchAndPaginationTable style="width: 80%; margin-top: 10px;" :cols="cols" :apiRoute="apiRoute">
                </SearchAndPaginationTable>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import ThePageTitle from '@/components/ThePageTitle.vue';
import SearchAndPaginationTable from '@/components/SearchAndPaginationTable.vue';
import TheELearningMenu from '@/components/e-learning/TheELearningMenu.vue';
import TheELearningPage from '@/components/e-learning/TheELearningPage.vue';
import { useRoute } from 'vue-router';
import { watch, ref, onMounted } from 'vue';
const route = useRoute()

const is_detailed = ref(false)
const standardId = ref(route.params.standard_id);

const cols = [{ text: "Standard", jsonName: "standard" }, { text: "Art", jsonName: "type" }, { text: "Download", jsonName: "download" }, { text: "Download", jsonName: "download" }]
const apiRoute = "/v1/standards"


watch(
    () => route.params.standard_id,
    async (newStandardId) => {
        standardId.value = newStandardId; // Update the ref
        is_detailed.value = !!newStandardId; // 
    }
);
onMounted(() => {
    if (standardId.value) {
        is_detailed.value = true;
    }
});
</script>

<style scoped>
.standard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 25px;
}

.standard-detail-container {}
</style>