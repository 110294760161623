<template>
    <div class="page-container">
        <div v-for="content, index in page_content" :key="index">
            <h2>{{ content.title }}</h2>
            <div class="page-text">{{ content.text }}</div>
        </div>
    </div>
</template>
<script lang="ts" setup>
const page_content = [
    {
        title: "Test Title",
        text: '- Gute Tauchkenntnisse (Theorie und ausreichende Praxis), die dem Trainer nachgewiesen werden sollten. \n- Ein OWD/ JOWD - Tauchschein ist als Nachweis ausreichend. - Das Mindestalter beträgt 10 Jahre.',
    },
    {
        title: "Test Title",
        text: "- Gute Tauchkenntnisse (Theorie und ausreichende Praxis), die dem Trainer nachgewiesen werden sollten. \n - Ein OWD/ JOWD - Tauchschein ist als Nachweis ausreichend.\n - Das Mindestalter beträgt 10 Jahre.",
    },
    {
        title: "Test Title",
        text: "- Gute Tauchkenntnisse (Theorie und ausreichende Praxis), die dem Trainer nachgewiesen werden sollten. \n - Ein OWD/ JOWD - Tauchschein ist als Nachweis ausreichend.\n - Das Mindestalter beträgt 10 Jahre.",
    }
]
</script>
<style scoped>
.page-container {
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-left: 250px;
}

.page-text {
    white-space: pre-line;
}
</style>