<template>
    <div v-if="show" class="modal-overlay">
        <div class="modal-content">
            <p class="modal-text">
                <slot></slot>
            </p>
            <div class="modal-buttons">
                <button class="modal-button confirm" @click="confirmAction(true)">Yes</button>
                <button class="modal-button cancel" @click="confirmAction(false)">No</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: Boolean
    },
    emits: ['confirm'],
    setup(_, { emit }) {
        const confirmAction = (confirmed) => {
            emit('confirm', confirmed);
        };

        return {
            confirmAction
        };
    }
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    width: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}



.modal-text {
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
}

.modal-button {
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.confirm {
    background-color: #28a745;
    color: #fff;
}

.confirm:hover {
    background-color: #218838;
}

.cancel {
    background-color: #dc3545;
    color: #fff;
}

.cancel:hover {
    background-color: #c82333;
}
</style>