<!-- eslint-disable vue/html-indent -->
<template>
    <div>
        <div class="header-menu">
            <img style="height: 50px;" src="@/assets/logo_white.png">
            <div></div>
            <the-burger-menu @click="switchNav()" class="burger-menu"
                :class="{ navactiv: activeNav }"></the-burger-menu>
        </div>
        <div class="nav-bar" :class="{ navactiv: activeNav }">
            <div class="nav-list">
                <template v-for="menuPoint, index in menuPoints" :key="index">
                    <the-menu-item v-if="!menuPoint.children" :to="menuPoint.path" :text="menuPoint.name"
                        :icon="menuPoint.icon" @click="switchNav()"></the-menu-item>
                    <the-menu-item-nested v-else-if="menuPoint.children" :to="menuPoint.path" :text="menuPoint.name"
                        :icon="menuPoint.icon">
                        <template v-for="menuPointCildren, indexChildren in menuPoint.children" :key="indexChildren">
                            <the-menu-item :to="menuPoint.path + menuPointCildren.path" :text="menuPointCildren.name"
                                :icon="menuPointCildren.icon" @click="switchNav()"></the-menu-item>
                        </template>
                    </the-menu-item-nested>
                </template>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import TheMenuItem from '@/components/menu/TheMenuItem.vue'
import TheMenuItemNested from '@/components/menu/TheMenuItemNested.vue'
import TheBurgerMenu from '@/components/menu/TheBurgerMenu.vue'
import { faHouse, faUser, faAddressCard, faBookOpen, faCloudArrowDown, faMapLocationDot, faFileLines, faShoppingCart, faUserGroup, faSchool, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
const { tm } = useI18n()

const activeNav = ref(false)

const menuPoints = [
    {
        name: tm('menu.home'),
        path: "/home",
        icon: faHouse,
    },
    {
        name: tm('menu.profile'),
        path: "/profile",
        icon: faUser,
    },
    {
        name: tm('menu.brevet'),
        path: "/brevets",
        icon: faAddressCard,
    },
    {
        name: tm('menu.course'),
        path: "/courses",
        icon: faBookOpen,
    },
    {
        name: tm('menu.download'),
        path: "/downloads",
        icon: faCloudArrowDown,
    },
    {
        name: tm('menu.map'),
        path: "/map",
        icon: faMapLocationDot,
    },
    {
        name: tm('menu.standards'),
        path: "/standards",
        icon: faFileLines,
    },
    {
        name: tm('menu.shop'),
        path: "/shop",
        icon: faShoppingCart,
    },
    {
        name: tm('menu.trainer'),
        path: "/trainer",
        icon: faUserGroup,
    },
    {
        name: tm('menu.center'),
        path: "/center",
        icon: faSchool,
    },
    {
        name: tm('menu.statistic'),
        path: "/statistic",
        icon: faChartLine,
    },
    {
        name: tm('menu.admin.admin'),
        path: "/admin",
        icon: "admin",
        children: [
            {
                name: tm('menu.admin.center'),
                path: "/center",
                icon: "center",
            },
            {
                name: tm('menu.admin.diver'),
                path: "/diver",
                icon: "diver",
            },
            {
                name: tm('menu.admin.trainer'),
                path: "/trainer",
                icon: "trainer",
            },
            {
                name: tm('menu.admin.brevet'),
                path: "/brevet",
                icon: "brevet",
            },
            {
                name: tm('menu.admin.admin'),
                path: "/profile",
                icon: "profile",
            },
            {
                name: tm('menu.admin.news'),
                path: "/news",
                icon: "news",
            },
            {
                name: tm('menu.admin.print'),
                path: "/print",
                icon: "print",
            },
            {
                name: tm('menu.admin.verifikation'),
                path: "/verifikation",
                icon: "verifikation",
            },
            {
                name: tm('menu.admin.transformation'),
                path: "/transformation",
                icon: "transformation",
            },
            {
                name: tm('menu.admin.quesquestionnaire'),
                path: "/quesquestionnaire",
                icon: "questionnaire",
            },
            {
                name: tm('menu.admin.paylater'),
                path: "/paylater",
                icon: "paylater",
            },
            {
                name: tm('menu.admin.paylaterhistory'),
                path: "/paylaterhistory",
                icon: "paylaterhistory",
            },
            {
                name: tm('menu.admin.download'),
                path: "/download",
                icon: "download",
            },
            {
                name: tm('menu.admin.downloadgroup'),
                path: "/downloadgroup",
                icon: "downloadgroup",
            },
            {
                name: tm('menu.admin.shop'),
                path: "/shop",
                icon: "shop",
            },
            {
                name: tm('menu.admin.course'),
                path: "/course",
                icon: "course",
            },
            {
                name: tm('menu.admin.coursegroup'),
                path: "/coursegroup",
                icon: "coursegroup",
            },
            {
                name: tm('menu.admin.book'),
                path: "/book",
                icon: "book",
            },
            {
                name: tm('menu.admin.exam'),
                path: "/exam",
                icon: "exam",
            },
            {
                name: tm('menu.admin.standard'),
                path: "/standard",
                icon: "standard",
            },
            {
                name: tm('menu.admin.standardtyp'),
                path: "/standardtyp",
                icon: "standardtyp",
            },
            {
                name: tm('menu.admin.checklist'),
                path: "/checklist",
                icon: "checklist",
            },
        ]
    },
]

const switchNav = function () {
    activeNav.value = !activeNav.value
}

menuPoints.find(point => point.name === "Admin")?.children?.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
});
</script>
<style scoped>
.burger-menu {
    transition: 0.5s !important;
    width: 100px;
    height: 100px;
}

.header-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: var(--nav-header-text-color);
    background-color: var(--nav-header-background-color);
    height: 60px;
}

.title {
    text-align: start;
    padding: 5px;
}

.nav-bar {
    z-index: 11;
    background-color: var(--nav-side-bar-background-color);
    color: var(--nav-side-bar-text-color);
    left: -250px;
    top: 80px;
    height: 100%;
    width: 200px;
    transition: 0.5s;
    padding: 15px;
    overflow: scroll;
    position: fixed;
}

.nav-bar.navactiv {
    transform: translate(250px, 0) !important;
}

.nav-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: start;
}
</style>
