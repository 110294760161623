<template>
    <div id="nav-icon3" @click="toggleOpen" :class="{ open: isOpen }">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

const isOpen = ref(false)

const toggleOpen = () => {
    isOpen.value = !isOpen.value
}

</script>
<style scoped>
#nav-icon3 {
    width: 50px;
    height: 30px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon3 span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: var(--var-burger-menu-color);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

/* Icon 3 */

#nav-icon3 span:nth-child(1) {
    top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
    top: 12px;
}

#nav-icon3 span:nth-child(4) {
    top: 24px;
}

#nav-icon3.open span:nth-child(1) {
    top: 9px;
    width: 0%;
    left: 50%;
}

#nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
    top: 9px;
    width: 0%;
    left: 50%;
}
</style>