<template>
    <div>
        <div>
            Geschlächt
            <FontAwesomeIcon :icon="faVenusMars"></FontAwesomeIcon>
            <BasicSelect :value="genderSelect" :options="gender"></BasicSelect>
        </div>
        <div>
            Size
            <FontAwesomeIcon :icon="faShirt"></FontAwesomeIcon>
            <BasicSelect :options="gender"></BasicSelect>
        </div>
        <div>
            Shoes
            <FontAwesomeIcon :icon="faSocks"></FontAwesomeIcon>
            <BasicSelect :options="shoesSize"></BasicSelect>
        </div>
        <div>
            Gloves
            <FontAwesomeIcon :icon="faHand"></FontAwesomeIcon>
            <BasicSelect :options="gloveSize"></BasicSelect>
        </div>




    </div>
</template>
<script setup lang="ts">
import { faSocks, faShirt, faVenusMars, faHand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ref } from 'vue';
import BasicSelect from '../basic/BasicSelect.vue';
import { shoesSize, sizeMale, sizeFemale, gloveSize } from '@/resources/SizeConst';


const genderSelect = ref()
const gender = ref(["Male", "Female", "Other"])


</script>
<style scoped></style>